/* eslint-disable max-len, react/no-multi-comp */
export function CatalogMoreIcon() {

  return (
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.2321 10.9949L12.9589 2.67798C12.8135 2.53248 12.6981 2.3595 12.6193 2.16898C12.5405 1.97846 12.5 1.77415 12.5 1.56781C12.5 1.36147 12.5405 1.15716 12.6193 0.966636C12.6981 0.776113 12.8135 0.603132 12.9589 0.457639C13.2535 0.164446 13.6512 0 14.0656 0C14.48 0 14.8777 0.164446 15.1723 0.457639L24.5453 9.8773C24.8285 10.1633 24.9912 10.5482 24.9997 10.9518C25.0081 11.3554 24.8616 11.7468 24.5906 12.0446L15.1788 21.5378C15.0339 21.684 14.8618 21.8001 14.6723 21.8794C14.4828 21.9587 14.2796 21.9996 14.0744 21.9999C13.8691 22.0002 13.6659 21.9599 13.4761 21.8811C13.2864 21.8024 13.114 21.6868 12.9686 21.5411C12.8233 21.3953 12.7079 21.2221 12.6291 21.0315C12.5503 20.8408 12.5096 20.6364 12.5093 20.4299C12.5086 20.0129 12.6727 19.6128 12.9654 19.3175L21.2321 10.9949Z"
        fill="var(--primary)"
      />
      <path
        d="M8.73211 10.9949L0.458897 2.67798C0.313464 2.53248 0.198052 2.3595 0.119297 2.16898C0.0405431 1.97846 0 1.77415 0 1.56781C0 1.36147 0.0405431 1.15716 0.119297 0.966636C0.198052 0.776113 0.313464 0.603132 0.458897 0.457639C0.753521 0.164446 1.1512 0 1.5656 0C1.98001 0 2.37769 0.164446 2.67231 0.457639L12.0453 9.8773C12.3285 10.1633 12.4912 10.5482 12.4997 10.9518C12.5081 11.3554 12.3616 11.7468 12.0906 12.0446L2.67879 21.5378C2.53388 21.684 2.36176 21.8001 2.17227 21.8794C1.98277 21.9587 1.77961 21.9996 1.57437 21.9999C1.36914 22.0002 1.16586 21.9599 0.976132 21.8811C0.786406 21.8024 0.613952 21.6868 0.468617 21.5411C0.323283 21.3953 0.207914 21.2221 0.129096 21.0315C0.0502787 20.8408 0.00955746 20.6364 0.00925659 20.4299C0.00864897 20.0129 0.17272 19.6128 0.465377 19.3175L8.73211 10.9949Z"
        fill="var(--primary)"
      />
    </svg>
  );

}

export function SlidePrevIcon() {

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.7" cx="17" cy="17" r="17" fill="#EBEBEB" />
      <path
        d="M19.0026 21.761L19.0026 21.761L13.2703 16.2135C13.2703 16.2135 13.2702 16.2134 13.2702 16.2134C13.0108 15.9621 12.5899 15.9623 12.3304 16.2134L11.9974 16.5357C11.7341 16.7906 11.7344 17.2088 11.9973 17.4635L11.9974 17.4635L17.7297 23.0111C17.7297 23.0111 17.7297 23.0111 17.7297 23.0111C17.9893 23.2623 18.4101 23.2623 18.6697 23.0111L18.6697 23.0111L19.0025 22.6889C19.0025 22.6888 19.0026 22.6888 19.0026 22.6888C19.0026 22.6888 19.0026 22.6888 19.0026 22.6888C19.2659 22.4341 19.2657 22.0157 19.0026 21.761Z"
        fill="var(--primary)"
        stroke="var(--primary)"
        strokeWidth="0.4" />
      <path
        d="M13.2707 17.7865L13.2707 17.7865C13.0112 18.0377 12.5903 18.0378 12.3309 17.7865C12.3308 17.7865 12.3308 17.7864 12.3308 17.7864L11.9978 17.4642L11.9978 17.4641C11.7349 17.2095 11.7345 16.7911 11.9979 16.5363L17.7299 10.9885L17.7299 10.9885C17.9894 10.7376 18.4102 10.7372 18.6697 10.9885L13.2707 17.7865ZM13.2707 17.7865L19.0025 12.2387M13.2707 17.7865L18.6698 10.9886L19.0026 11.3108C19.0026 11.3108 19.0026 11.3108 19.0026 11.3108C19.2658 11.5656 19.2659 11.984 19.0025 12.2387M19.0025 12.2387L18.8635 12.0949L19.0026 12.2386L19.0025 12.2387Z"
        fill="var(--primary)"
        stroke="var(--primary)"
        strokeWidth="0.4" />
    </svg>
  );

}

export function SlideNextIcon() {

  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.7" cx="17" cy="17" r="17" transform="rotate(-180 17 17)" fill="#EBEBEB" />
      <path
        d="M14.9974 12.239L14.9974 12.239L20.7297 17.7865C20.9892 18.0379 21.4101 18.0378 21.6696 17.7866L22.0026 17.4643C22.2659 17.2094 22.2656 16.7912 22.0027 16.5365L22.0026 16.5365L16.2703 10.9889C16.0107 10.7377 15.5899 10.7377 15.3303 10.9889L15.3303 10.9889L14.9975 11.3111C14.9975 11.3112 14.9975 11.3112 14.9974 11.3112C14.9974 11.3112 14.9974 11.3112 14.9974 11.3112C14.7341 11.5659 14.7343 11.9843 14.9974 12.239Z"
        fill="var(--primary)"
        stroke="var(--primary)"
        strokeWidth="0.7" />
      <path
        d="M14.9977 21.7613C14.7344 22.016 14.7345 22.4344 14.9976 22.6892C14.9976 22.6892 14.9977 22.6892 14.9977 22.6892L15.3305 23.0114C15.59 23.2628 16.0109 23.2624 16.2703 23.0115L16.2704 23.0115L22.0023 17.4637C22.2658 17.2089 22.2654 16.7905 22.0025 16.5359L22.0024 16.5358L21.6695 16.2136C21.6694 16.2136 21.6694 16.2135 21.6694 16.2135C21.4099 15.9622 20.9891 15.9623 20.7296 16.2135L20.7295 16.2135L14.9977 21.7613ZM14.9977 21.7613L15.1368 21.9051L14.9977 21.7614L14.9977 21.7613Z"
        fill="var(--primary)"
        stroke="var(--primary)"
        strokeWidth="0.4" />
    </svg>
  );

}


export function LupineNextIcon() {
  return (
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="41" stroke="#EB4D27"/>
      <path d="M18.7256 15.3322L23.9326 20.283L24.3137 20.6453L23.9326 21.0077L18.7256 25.9585L19.2059 26.4152L25.2744 20.6453L19.2059 14.8755L18.7256 15.3322Z" fill="white" stroke="#EB4D27"/>
    </svg>

  )
}

export function LupinePrevIcon() {
  return(
    <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-0.5" y="0.5" width="39" height="41" transform="matrix(-1 0 0 1 39 0)" stroke="#EB4D27"/>
      <path d="M21.2744 15.3322L16.0674 20.283L15.6863 20.6453L16.0674 21.0077L21.2744 25.9585L20.7941 26.4152L14.7256 20.6453L20.7941 14.8755L21.2744 15.3322Z" fill="white" stroke="#EB4D27"/>
    </svg>

  )
}
